import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const MessageForm = styled.div`
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  gap: 1rem 1rem;
  max-width: 43.75rem;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const TextInput = styled.input(
  ({ theme }) => css`
    border-radius: 0;
    border: 2px solid ${theme.colors.primary.base};
    height: 3rem;
    color: ${theme.colors.n[60]};
    font-family: ${theme.typography.fonts.primary};
    font-size: ${theme.typography.sizes.medium};
    font-weight: ${theme.typography.weights.thin};
    outline-color: ${theme.colors.primary[0]};
    padding-left: 1rem;
    box-sizing: border-box;
    width: 100%;
    grid-column: span 2;
    &::placeholder {
      color: ${theme.colors.primary.base};
      font-family: ${theme.typography.fonts.primary};
      font-weight: ${theme.typography.weights.thin};
    }
    @media ${device.tablet} {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        grid-column: span 1;
      }
    }
  `
);

export const TextAreaInput = styled.textarea(
  ({ theme }) => css`
    grid-column: span 2;
    height: 10rem;
    resize: none;
    border-radius: 0;
    border: 2px solid ${theme.colors.primary.base};
    padding-top: .5rem;
    padding-left: 1rem;
    color: ${theme.colors.n[60]};
    font-family: ${theme.typography.fonts.primary};
    font-size: ${theme.typography.sizes.medium};
    font-weight: ${theme.typography.weights.thin};
    outline-color: ${theme.colors.primary[0]};
    width: 100%;
    box-sizing: border-box;
    &::placeholder {
      color: ${theme.colors.primary.base};
      font-family: ${theme.typography.fonts.primary};
      font-weight: ${theme.typography.weights.thin};
    }
  `
);

export const SubmitButton = styled.button(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: span 2;
    outline-color: ${theme.colors.primary.base};
    background: ${theme.colors.primary[0]};
    color: ${theme.colors.white};
    height: 3rem;
    font-family: ${theme.typography.fonts.primary};
    font-size: ${theme.typography.sizes.medium};
    font-weight: ${theme.typography.weights.thin};
    text-transform: uppercase;
    width: 100%;
    max-width: 12.5rem;
  `
);

export const StyledImage = styled.img`
  height: 1.2rem;
  margin-right: .5rem;
`;
