export const getTranslatedQuestions = (t) => ([
  {
    question: t("faqs_question_01"),
    answer: t("faqs_answer_01")
  },
  {
    question: t("faqs_question_02"),
    answer: t("faqs_answer_02")
  },
  {
    question: t("faqs_question_03"),
    answer: t("faqs_answer_03")
  },
  {
    question: t("faqs_question_05"),
    answer: t("faqs_answer_05")
  },
  {
    question: t("faqs_question_06"),
    answer: t("faqs_answer_06")
  },
  {
    question: t("faqs_question_07"),
    answer: t("faqs_answer_07")
  },
  {
    question: t("faqs_question_08"),
    answer: t("faqs_answer_08")
  },
  {
    question: t("faqs_question_10"),
    answer: t("faqs_answer_10")
  },
]);
