const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1366px",
  desktop: "1440px",
  desktopL: "1920px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};

export const deviceMax = {
  mobileSMax: `(max-width: ${size.mobileS})`,
  mobileMMax: `(max-width: ${size.mobileM})`,
  mobileLMax: `(max-width: ${size.mobileL})`,
  tabletMax: `(max-width: ${size.tablet})`,
  laptopMax: `(max-width: ${size.laptop})`,
  laptopLMax: `(max-width: ${size.laptopL})`,
  desktopMax: `(max-width: ${size.desktop})`,
  desktopLMax: `(max-width: ${size.desktopL})`,
};
