/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import {
  NavWrapper,
  NavList,
  NavListItem,
  NavLink,
} from "./Navbar.styled";
import { getMenuLinks } from "../../../constants/menu";
import Hamburger from "hamburger-react";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { t } = useTranslation(); 
  const menuTranslated =  getMenuLinks(t); 
  
  return (
    <>
      <NavWrapper aria-label="Main Navigation" aria-expanded={isMenuOpened}>
        <NavList expanded={isMenuOpened} role="menu">
          {menuTranslated.map((menuItem, index) => {
            return (
              <NavListItem key={`nav-item-${index}`}>
                <NavLink
                  role="menuitem"
                  to={{
                    pathname: "/",
                    hash: menuItem.link,
                  }}
                >
                  {menuItem.title}
                </NavLink>
              </NavListItem>
            );
          })}
        </NavList>
      </NavWrapper>
      <div style={{ zIndex: 3 }}>
        <Hamburger
          toggled={isMenuOpened}
          toggle={setIsMenuOpened}
          aria-expanded={isMenuOpened}
        />
      </div>
    </>
  );
};

export default Navbar;
