import styled, { css } from 'styled-components';
import { device } from '../../../utils/device';

export const HowItWorksSection = styled.section(
  ({ theme }) => css`
    background-color: white;
    background-repeat: no-repeat;
    padding: 1rem 5rem 0 0;
    z-index: -1;
  `
);

export const HowItWorksWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const HowItWorksContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  @media ${device.laptop} {
    width: 50%;
  }
`;

export const HowItWorksTitle = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 30px;
    line-height: 1.714;
  `
);

export const HowItWorksDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary['dark-90']};
    margin: 0 0 30px;
    font-size: ${theme.typography.sizes.large};
    line-height: 1.714;
    text-align: justify;
  `
);

export const HowItWorksGrid = styled.div`
  display: block;
  box-sizing: border-box;
  & .item1 {
    grid-area: item1;
  }
  & .item2 {
    grid-area: item2;
  }
  & .item3 {
    grid-area: item3;
  }
  & .item4 {
    grid-area: item4;
  }
  & .item5 {
    grid-area: item5;
  }
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: auto;
    gap: 1.5rem;
    grid-template-areas:
      'item1 item1 item2 item2 item3 item3'
      'item4 item4 item4 item5 item5 item5';
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'item1 item2 item3 item4 item5';
  }
`;
