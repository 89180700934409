import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const FaqSection = styled.section(
  ({theme}) => css`
    padding: 3rem 0;
    min-height: 500px;
    background: ${theme.colors.tbBlue.base};
    color: ${theme.colors.white};
  `,
);

export const FaqSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const FaqQuestionSection = styled.div(
  ({theme}) => css`
    box-sizing: border-box;
    @media ${device.laptop} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  `,
);

export const FaqSectionTitle = styled.h2(
  ({theme}) => css`
    text-transform: uppercase;
    text-align: center;
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    margin: 0 0 3rem;
  `,
);