import React from "react";
import { useTranslation } from "react-i18next";
import { StyledCopyText, StyledCopyWrapper, StyledFooter, StyledFooterWrapper, StyledLink, StyledList, StyledListItem, StyledNav } from "./Footer.styled";



const Footer = () => {
  const { t } = useTranslation(); 

  return <StyledFooter>
    <StyledFooterWrapper>
      <StyledNav>
        <StyledList>
          <StyledListItem>
            <StyledLink to="/">{t("footer_menu_home_title")}</StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/terms" target="_blank">{t("footer_menu_terms_title")}</StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/privacy" target="_blank">{t("footer_menu_privacy_title")}</StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to={{
              pathname: "/",
              hash: '#faqs'
            }}>{t("footer_menu_faqs_title")}</StyledLink>
          </StyledListItem>
          <StyledListItem>
          <StyledLink to={{
              pathname: "/",
              hash: '#contact'
            }}>{t("footer_menu_contact_title")}</StyledLink>
          </StyledListItem>
        </StyledList>
      </StyledNav>
      <StyledCopyWrapper>
        <StyledCopyText>&copy; Copyright {new Date().getFullYear()} Tendbe OÜ. All Rights Reserved.</StyledCopyText>
      </StyledCopyWrapper>
    </StyledFooterWrapper>
  </StyledFooter>
};

export default Footer;
