import styled from 'styled-components';

export const Preloader = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #334768;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PreloaderGif = styled.div`
  height: 250px;
  width: 250px;
  position: fixed;
  background-image: url(images/tb-splash-screen.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
