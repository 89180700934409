import Slider from "react-slick";
import "./index.css";

import {
  QR,
  Container,
  QuestionTitle,
  Bubble,
  Card,
  GridContainer,
  Answer,
  Row2,
} from "./Carousel.styled";

import Q1 from "../../../../static/qr_images/Q1/1.png";
import Q2 from "../../../../static/qr_images/Q1/2.png";
import Q3 from "../../../../static/qr_images/Q1/3.png";
import Q4 from "../../../../static/qr_images/Q2/1.png";
import Q5 from "../../../../static/qr_images/Q2/2.png";
import Q6 from "../../../../static/qr_images/Q2/3.png";
import Q7 from "../../../../static/qr_images/Q3/1.png";
import Q8 from "../../../../static/qr_images/Q3/2.png";
import Q9 from "../../../../static/qr_images/Q3/3.png";
import Q10 from "../../../../static/qr_images/Q4/1.png";
import Q11 from "../../../../static/qr_images/Q4/2.png";
import Q12 from "../../../../static/qr_images/Q4/3.png";
import bubble_path from "../../../../static/qr_images/Bubble.svg";

const QuestionsList = [
  {
    question: "How do you incorporate feedback collection during an event?",
    answers: [
      { answer: "Real-time Surveys", QR_path: Q1 },
      { answer: "Live Polling", QR_path: Q2 },
      { answer: "Social Media Engagement", QR_path: Q3 },
    ],
  },

  {
    question: "What is the best data to collect during an event?",
    answers: [
      { answer: "Demographics", QR_path: Q4 },
      { answer: "Networking Data", QR_path: Q5 },
      { answer: "Contacting info", QR_path: Q6 },
    ],
  },
  {
    question: "What makes an event engaging for attendees? ",
    answers: [
      { answer: "Compelling Content", QR_path: Q7 },
      { answer: "Networking Opportunities", QR_path: Q8 },
      { answer: "Interactive Experiences", QR_path: Q9 },
    ],
  },
  {
    question: "How do you measure the success of your events?",
    answers: [
      { answer: "Attendee Satisfaction", QR_path: Q10 },
      { answer: "Financial Metrics", QR_path: Q11 },
      { answer: "Engagement Metrics", QR_path: Q12 },
    ],
  },
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style }} onClick={onClick}></div>
  );
};

const Carrousel = () => {
  const settings = {
    dots: true,
    speed: 2000,
    autoplaySpeed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Container>
      <Bubble bubble_path={bubble_path}>
        <h4>Scan me for Voting</h4>
      </Bubble>

      <div style={{ padding: "0 10% 0 10%" }}>
        <Slider {...settings}>
          {QuestionsList.map((object, index) => (
            <GridContainer key={index}>
              <QuestionTitle>{object.question}</QuestionTitle>
              <Row2>
                {object.answers.map((answer, index) => (
                  <Card key={answer.id} id={`card_${index}`}>
                    <QR src={answer.QR_path} />
                    <Answer>{answer.answer}</Answer>
                  </Card>
                ))}
              </Row2>
            </GridContainer>
          ))}
        </Slider>
      </div>
    </Container>
  );
};

export default Carrousel;
