import React from 'react'
import { TeamPhotoContainer, TeamPhoto, TeamMemberCard, TeamEmail, TeamMemberName, TeamMemberRole } from './TeamMember.styled';
import { MdMail } from "react-icons/md";

interface ITeamMember {
  name: string;
  role: string;
  photo?: string;
  contact: string;
}

const TeamMember = ({name, role, photo, contact}: ITeamMember) => {
  return (
    <TeamMemberCard>
      <TeamPhotoContainer>
        <div id="overlay"></div>
        <TeamPhoto src={photo} alt={name} />
        <TeamEmail href={`${contact}`} aria-hidden="true" target="_blank" rel="noopener noreferrer">
          <MdMail />
        </TeamEmail>
      </TeamPhotoContainer>
      <TeamMemberName>{name}</TeamMemberName>
      <TeamMemberRole>{role}</TeamMemberRole>
    </TeamMemberCard>
  )
}

export default TeamMember
