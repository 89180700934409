import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const TeamMemberCard = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const TeamPhotoContainer = styled.figure`
  height: 120px;
  width: 120px;
  overflow: hidden;
  margin: 0;
  border-radius: 50%;
  position: relative;
  &:hover {
    & img {
      transform: scale(1.1);
    }
    & #overlay {
      opacity: 1;
    }
    & a {
      opacity: 1;
    }
  }
  & #overlay {
    position: absolute;
    background: rgba(51, 73, 104, 0.8);
    left: 0;
    height: 100%;
    top: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }
  @media ${device.laptop} {
    height: 150px;
    width: 150px;
  }
`;

export const TeamPhoto = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
`;

export const TeamEmail = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.white};
    position: absolute;
    top: 45px;
    left: 45px;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border: 1px solid ${theme.colors.white};
    border-radius: 50%;
    padding: 5px 6px 3px;
    @media ${device.laptop} {
      top: 60px;
      left: 60px;
    }
  `
);

export const TeamMemberName = styled.h3(
  ({ theme }) => css`
    color: ${theme.colors.primary["dark-90"]};
    margin: 1rem 0 0.5rem;
  `
);

export const TeamMemberRole = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary["dark-90"]};
    margin: 0;
  `
);
