import React from "react";

import TeamMember from "../../atoms/TeamMember/TeamMember.component";
import {
  TeamSection,
  TeamSectionWrapper,
  TeamSectionTitle,
  Paragraph,
  TeamGrid,
} from "./Team.styled";
import { teamMembers } from "../../../constants/team";
import { newLineText } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  
  return (
    <TeamSection id="team">
      <TeamSectionWrapper>
        <TeamSectionTitle>{t("team.title")}</TeamSectionTitle>
        {newLineText(t("team.description")).map((text, index) => (
          <Paragraph key={`paragraph-${index}`}>{text}</Paragraph>
        ))}
        <TeamGrid>
          {teamMembers.map((member) => (
            <TeamMember
              {...member}
              key={`team-${member.name
                .replace(/\s+/g, "-")
                .toLocaleLowerCase()}`}
            />
          ))}
        </TeamGrid>
      </TeamSectionWrapper>
    </TeamSection>
  );
};

export default Team;
