import React from "react";

import tendbeFeatures from "../../../static/tb-features.png";
import { getFeaturesTranslated } from "../../../constants/features";
import {
  FeatureImage,
  FeatureItem,
  FeaturesContainer,
  FeaturesGrid,
  FeaturesSection,
  FeatureTitle,
} from "./Features.styled";
import { Trans, useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation(); 
  const trans =  getFeaturesTranslated(t); 
  const renderText = (desc: string, link: string | undefined) => {
    const data = <Trans i18nKey="features_description_06"> <a href={link} target="_blank" rel="noreferrer"> </a> </Trans>    
    return data;
  }  

  return (
    <FeaturesSection id="features">
      <FeaturesContainer>
        <FeatureTitle>{t("features")}</FeatureTitle>
        <FeaturesGrid>
          {trans.map((item) => (            
            <FeatureItem key={`feature-${item.title.replace(/\s+/g, '-').toLocaleLowerCase()}`}>
              <div>
                <img src={item.icon} alt={item.title} />
                <h3>{item.title}</h3>
              </div>
              {
                (item.link) ? renderText(item.description, item.link) : item.description
              }
            </FeatureItem>
          )) }          
          <FeatureImage>
            <img src={tendbeFeatures} alt="Tendbe Demo" />
          </FeatureImage>
          
        </FeaturesGrid>
      </FeaturesContainer>
    </FeaturesSection>
  );
};

export default Features;
