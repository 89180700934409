import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const TeamSection = styled.section(
  ({ theme }) => css`
    padding: 3rem 0;
    min-height: 500px;
    background: ${theme.colors.white};
  `
);

export const TeamSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const TeamSectionTitle = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 30px;
    line-height: 1.714;
  `
);

export const Paragraph = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary["dark-90"]};
    margin: 0 0 1rem;
    font-size: ${theme.typography.sizes.large};
    line-height: 1.714;
    text-align: center;
  `
);

export const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
