import React from "react";
import { AccordionItemState } from "react-accessible-accordion";
import {
  IQuestion,
  StyledAccordion,
  StyledAccordionItem,
  StyledAccordionItemButton,
  StyledAccordionItemHeading,
  StyledAccordionItemPanel,
  StyledIcon,
} from "./Questions.styled";
import { MdAddCircle } from "react-icons/md";

interface IQuestions {
  id?: string;
  questions: IQuestion[];
}

const Questions = ({ id, questions }: IQuestions) => {
  return (
    <StyledAccordion id={id} allowZeroExpanded>
      {questions.map((item, index) => (
        <StyledAccordionItem key={`question-${index}`}>
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState>
                {({ expanded }) => (
                  <StyledIcon isOpen={expanded}>
                    <MdAddCircle />
                  </StyledIcon>
                )}
              </AccordionItemState>
              <h3>{item.question}</h3>
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <p>{item.answer}</p>
          </StyledAccordionItemPanel>
        </StyledAccordionItem>
      ))}
    </StyledAccordion>
  );
};

export default Questions;
