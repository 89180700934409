import styled, { css } from "styled-components";
import { device, deviceMax } from "../../../utils/device";

export const RightSideButton = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;   
    @media screen and ${device.laptop} and ${deviceMax.desktopLMax} {
      align-items: flex-start;
  }
  `
);

export const Wave = styled.div`
  position: absolute;
  bottom: -50px;
  width: 100%;
  height: 20vw;
  background: url("images/hero_wave.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 3;
`;


export const Button = styled.a`
    display: block;
    width: 100%;
    border-radius: 2em;
    background-color: #1fc2ff;
    color: #fff;
    outline: none;
    border: none;
    padding: 12.5px 0;
    margin-bottom: 0.5em;
    text-align: center;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    
    transition: transform 0.8s,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    &:hover {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
    z-index: 10;
    /* @media screen and ${device.laptop}  {
      position: relative;    
      bottom: 3rem;
    }   */
    @media screen and ${device.tablet}  {
      width: 85%;
    }  
    @media (min-width: 1024px) {
      width: 12rem;
    }
`;

export const HeroSection = styled.section`
  background: #244a78;
  display: flex;
  flex-direction: column;
  min-height: 58rem;
  height: 75vh;
  max-height: 100vh;
  position: relative;
  z-index: 0;
  background-size: cover;
  margin-bottom: -20vw;
  @media screen and ${device.mobileS} and ${deviceMax.mobileLMax} {
    height: 80vh;
    max-height: 85vh;
    min-height: 48rem;
  }  
  @media screen and (min-width: 1367px) and (min-height: 769px) {
    height: 75vh;
  }  
  @media screen and ${device.laptopL} and ${deviceMax.desktopLMax} {
    height: 85vh;
    max-height: 100vh;
  }  
 
  @media ${device.desktopL} {
    height: 90vh;    
  }  
`;

export const Overlay = styled.div`
  background: url("images/hero_background.png");
  background-position: center bottom;
  background-size: cover;
  left: 0;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const HeroContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  z-index: 3;
  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const Content = styled.div`
  color: white;
  position: relative;
  width: 100%;
  z-index: 2;
  padding-top: 9rem;
  @media ${device.tablet} {
    width: 48vw;
    padding-top: 12rem;
  }
  @media ${device.desktopL} {
    width: 34vw;
  }
`;

export const Illustration = styled.div`
  width: 95vw;
  height: 100%;
  background: url("images/hero_illustration.svg");
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  bottom: -2vw;
  position: absolute;
  right: 1.5vw;
  z-index: 2;
  @media ${device.mobileS} {
    height: 50%;
    width: 100vw;
  }
  @media ${device.mobileM} {
    height: 80%;
    width: 100vw;
  }
  @media ${device.mobileM} {
    height: 100%;
    width: 100vw;
  }
  @media ${device.mobileL} {
    height: 100%;
    width: 78vw;
  }
  @media ${device.tablet} {
    width: 60vw;
    bottom: 0;
    height: 100%;
    right: 1vw;
  }
  @media ${device.laptopL} {
    right: 0vw;
  }
  @media ${device.desktop} {
    width: 50vw;
    bottom: 0;
    right: 2vw;
  }
`;

export const HeroTitle = styled.h1(
  ({ theme }) => css`
    font-size: ${theme.typography.sizes.extraExtraLarge};
    line-height: 1.5;
    margin: 0 0 0.8rem;
    @media ${device.tablet} {
      font-size: ${theme.typography.sizes.huge};
      line-height: 1.714;
      margin: 0 0 1.5rem;
    }
    @media ${device.desktop} {
      font-size: ${theme.typography.sizes.huge};
      margin: 0 0 2rem;
    }
  `
);

export const HeroDescription = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
  `
);

export const HeroParagraph = styled.p(
  ({ theme }) => css`
    font-family: ${theme.typography.fonts.primary};
    font-size: ${theme.typography.sizes.large};
    line-height: 1.5;
    margin: 0 0 0.5rem;
    @media ${device.tablet} {
      font-size: ${theme.typography.sizes.large};
      line-height: 1.714;
      margin: 0 0 0.8rem;
    }
    @media ${device.desktop} {
      font-size: ${theme.typography.sizes.extraLarge};
      margin: 0 0 1rem;
    }
  `
);

export const HeroItalicParagraph = styled(HeroParagraph)`
  font-style: italic;
`;

