import styled, { css } from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { device } from "../../../utils/device";

export interface IQuestion {
  question: string;
  answer: string;
}

export const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `
);

export const StyledAccordionItem = styled(AccordionItem)(
  ({ theme }) => css`
    width: 100%;
  `
);

export const StyledAccordionItemHeading = styled(AccordionItemHeading)(
  ({ theme }) => css`
    font-size: ${theme.typography.sizes.extraLarge};
    line-height: 1.2;
  `
);

export const StyledAccordionItemButton = styled(AccordionItemButton)(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    box-sizing: border-box;
    outline-color: ${theme.colors.primary[0]};
    min-height: 3.75rem;
    padding: .5rem 1rem;
    & h3 {
      font-size: ${theme.typography.sizes.large};
      font-weight: ${theme.typography.weights.regular};
      margin: 0;
    }
    @media ${device.laptop} {
      & h3 {
        font-size: ${theme.typography.sizes.extraLarge};
      }
    }
  `
);

export const StyledAccordionItemPanel = styled(AccordionItemPanel)(
  ({ theme }) => css`
    font-size: ${theme.typography.sizes.medium};
    line-height: 1.5;
    padding: 0 1rem 1rem;
    margin-left: 3rem;
    & p {
      text-align: justify;
      font-size: ${theme.typography.sizes.large};
      margin: 0;
    }
  `
);

interface IIconProps {
  isOpen?: boolean;
}

export const StyledIcon = styled.div<IIconProps>(
  ({ isOpen }) => css`
    width: 1.6rem;
    height: 1.3rem;
    margin-right: 1rem;
    flex-shrink: 0;
    & svg {
      width: 100%;
      height: 100%;
      transform: ${isOpen ? `rotate(45deg)` : `rotate(0)`};
      transition: all .3s ease-in-out;
    }
    @media ${device.laptop} {
      width: 2rem;
      height: 1.8rem;
    }
  `
);