import styled, { css, keyframes } from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { device } from "../../../utils/device";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

interface INavList {
  expanded?: boolean;
}

export const NavWrapper = styled.nav``;

export const NavList = styled.ul<INavList>(
  ({ expanded }) => css`
    height: 100%;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    opacity: ${expanded ? 1 : 0};
    transform: ${expanded ? `translateY(0)` : `translateY(-30px)`};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    background: rgb(36, 74, 120, .9);
    display: ${expanded ? `flex` : `none`};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: ${expanded ? fadeIn : fadeOut} .3s ease-in-out;
    @media ${device.laptop} {
      display: flex;
      flex-direction: row;
      position: relative;
      height: auto;
      width: auto;
      background: transparent;
    }
    will-change: opacity, transform;
  `
);

export const NavListItem = styled.li`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const NavLink = styled(Link)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    padding: 0 0.5rem;
    height: 100%;
    box-sizing: border-box;
    font-size: ${theme.typography.sizes.extraLarge};
    margin: .5rem 0;
    outline-color: ${theme.colors.primary[0]};
    @media ${device.laptop} {
      font-size: ${theme.typography.sizes.medium};
    }
  `
);
