import styled, { css } from "styled-components";

export const QR = styled.img`
  width: 200px;
  height: 200px;
  aspect-ratio: 1/1;
`;

export const QuestionTitle = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: left;
    text-transform: uppercase;
    padding: 0 0 30px;
    line-height: 2;
    text-align: center;
  `
);

export const Answer = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: 20px;
    font-weight: ${theme.typography.weights.medium};
    text-align: left;
    text-transform: uppercase;
    margin-top: 20px;
    line-height: 2;
    text-align: center;
    max-width: 200px;
  `
);

export const Bubble = styled.div`
  background-image: url(${(props) => props.bubble_path});
  height: 120px;
  width: 120px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  position: absolute;
  right: 10%;
  top: 22%;
  z-index: 2;
  @media (max-width: 1437px) {
    display: none;
  }
`;

export const Card = styled.div`
  text-align: center;
  color: black;
`;

export const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px ;
  padding-top: 5vh;
  background-color: white;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Row2 = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
