import React, { useEffect, useState, useRef } from "react";
import {
  CarouselComponent,
  CarouselControls,
  CarouselInner,
  CarouselNav,
} from "./Carousel.styled";
import CarouselItem from "./CarouselItem.component";

export interface CarouselInterface {
  autoScroll?: boolean;
  autoScrollInterval?: number;
  children: any;
  variant: 'white' | 'default';
}

export default function Carousel(props: CarouselInterface) {
  const { autoScroll, autoScrollInterval, children, variant = 'default' } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const rotate = useRef(0);

  useEffect(() => {
    function getAutoIndex() {
      const lastIndex = children.length - 1;
      let autoIndex;

      if (activeIndex === 0) {
        autoIndex = activeIndex + 1;
      } else if (activeIndex === lastIndex) {
        autoIndex = 0;
      } else {
        autoIndex = activeIndex + 1;
      }

      return autoIndex;
    }

    if (autoScroll && children.length > 1) {
      rotate.current = window.setInterval(() => {
        setActiveIndex(getAutoIndex());
      }, autoScrollInterval);
    }

    return () => {
      if (rotate.current) {
        clearInterval(rotate.current);
      }
    };
  }, [activeIndex, children, autoScrollInterval, autoScroll]);

  function displayChildren() {
    return children.map((child: any, index: number) => {
      return (
        <CarouselItem
          activeIndex={activeIndex}
          index={index}
          item={child}
          key={index}
        />
      );
    });
  }

  function navigate(event: any) {
    const { current } = rotate;
    const index = event.currentTarget.getAttribute("data-index");
    setActiveIndex(parseInt(index, 10));
    if (current) {
      clearInterval(current);
    }
  }

  function renderControls() {
    if (children.length > 1) {
      return children.map((child: any, index: number) => {
        return (
          <CarouselNav
            index={index}
            activeIndex={activeIndex}
            key={index}
            data-index={index}
            onClick={navigate}
            variant={variant}
          />
        );
      });
    } else {
      return null;
    }
  }

  return (
    <CarouselComponent>
      <CarouselInner>{displayChildren()}</CarouselInner>
      <CarouselControls>{renderControls()}</CarouselControls>
    </CarouselComponent>
  );
}
