import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

interface IHeaderProps {
  fixedContent?: boolean;
  fixed?: boolean;
}

export const HeaderWrapper = styled.header<IHeaderProps>(
  ({ fixedContent, fixed }) => css`
    background: #334968;
    box-sizing: border-box;
    color: #fff;
    min-height: 50px;    
    padding: ${(fixed || fixedContent) ? `5px 0`: `15px 0`};
    margin: 0;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 5;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    &::after {
      display: 'block';
      content: " ";
      width: 100%;
      height: 5px;
      background: linear-gradient(
        90deg,
        rgba(250, 47, 92, 1) 0%,
        rgba(202, 88, 239, 1) 50%,
        rgba(68, 220, 255, 1) 100%
      );
      position: absolute;
      bottom: -5px;
      left: 0;
    }
    & figure {
      width: ${fixed ? "100px" : "170px"}
    }
  `
);

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 170px 1fr 50px;
    place-items: center;
  }
`;