import styled, { css } from "styled-components";
import { device } from "../utils/device";

export const TermsSection = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.white};      
    color: black;
    background-repeat: no-repeat;
    padding: 10vw 0 40px;
    position: relative;
    z-index: 1;     
  `
);

export const TermsWrapper = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    
`;

export const TermsContent = styled.div`
      width: auto;
    max-width: 990px;
    margin: 0px auto;
    padding: 0px 22px;
    text-align: justify;
    line-height: 25px;
    font-size: 14px;
`;

export const Illustrations = styled.div`  
  width: 800px;
  height: 650px;
  background: url("images/hero_illustration.svg");
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  bottom: 0;  
  right: 0vw;
  position: relative;
  margin: 0 auto;
  z-index: 2;
  @media ${device.mobileS} {
    height: 30vh;
    width: 100vw;
  } 
  @media ${device.mobileM} {
    height: 35vh;
    width: 100vw;
  }
  @media ${device.mobileL} {
    height: 65vw;
    width: 78vw;
  }
  @media ${device.tablet} {
    width: 50vh;
    bottom: 0;
    height: 40vh;    
  }
  @media ${device.laptopL} {
    width: 50vh;
  }
  @media ${device.desktop} {
    width: 50vh;
   
  } 
`;