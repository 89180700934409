import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/containers/Layout.component";

import {
  Illustrations,
  TermsContent,
  TermsSection,
  TermsWrapper,
} from "../styles/Terms.styled";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <Layout fixedContent>
      <TermsSection>
        <TermsWrapper>
          <TermsContent>
            <h1>{t("terms.title")}</h1>
            <p>
            <h5>Please Read Carefully Before Using Our Services</h5>
The following terms and conditions govern your use of Tendbe, website (<a href="https://tendbe.com">Tendbe</a>) and services accessed through the application <a href="https://app.beta.tendbe.com/">Tendbe App</a> operated by <a href="https://www.linkedin.com/company/64863001/" >Tendbe OÜ</a>. By accessing and using the Service and/or any information, content or materials made available on the Service, including by downloading any Tendbe add-in, you agree that such use is subject to these Terms of Service. If you do not agree to these Terms of Service, you may not use the Service.
You should check these Terms of Service and this Site periodically for modifications. Tendbe may modify the Site and/or the Terms of Service from time to time without notice to you. If Tendbe makes material changes to the Terms of Service we will post the revised Terms of Service and the revised effective date on this Site. Any continued use by you of the Service after the posting of such modified Terms of Service shall be deemed to indicate your agreement to such modified Terms of Service. Accordingly, if at any time you do not agree to be subject to any modified Terms of Service, you may no longer use the Service.	
Tendbe is an Estonian startup founded in December 2021, what started as an idea evolved into an MVP until reaching our current beta version. As such, we are an ongoing under construction project, which naturally comes with its array of technical issues and problems, we ask you to bare with us about this fact, if you’re experiencing technical issues while using Tendbe, please send us an email at team@tendbe.com, we will be happy to get back to you as soon as possible. 
Tendbe is a registered trademark owned by the Tendbe OÜ company, any use of our intellectual property will be subject to legal action in the courts of Estonia and worldwide. 
           </p>
            
{/* ############## */}
          <p><h4>POLL SERVICE</h4>
Tendbe provides the possibility of creation and/or use of channels of online polls, created and maintained by you. There may be unexpected downtimes, loss of data, delays or interruptions of the Service. Tendbe shall be in no way responsible for any losses or damages you or any third party may suffer as a result of any interruptions, delays or data losses or otherwise as a result of using the Service. Polls and their results are provided for approximation purpose only and are subject to errors and inaccuracies. Under no circumstances shall polls and their results be relied upon completely for any business purpose or any other decision of monetary or other value.
If you create a poll and choose to allow third parties to edit a poll, contribute content or post comments to your poll, you agree to monitor your poll independently, checking it at least once a day for any content that violates these Terms and Conditions and removing any such content immediately.		
            </p>
{/* ############## */}
            <p><h4>RESPONSIBILITY</h4>
Tendbe ordinarily does not review polls or messages before they are posted on Tendbe. Opinions expressed on a poll or its messages are not opinions of Tendbe. Tendbe makes no representations or warranties concerning the truth or falsity of any assertion of fact made by third party authors on a poll. You are solely responsible in any respect (copyrights etc.) for any information disclosed by using the Service.
Tendbe shall in no way be responsible for any losses, damages you or any third party may suffer as a result of any poll, message or content posted by you or any third party to the Service.	
            </p>           
{/* ############## */}
            <p><h4>RESTRICTIONS</h4>
            The following content is not permitted to appear in any polls, messages or content created through Tendbe:
            <ul>
                <li>Racism</li>
                <li> Harassmentl</li>
                <li> Adult contentl</li>
                <li> Obscene material</li>
                <li> Nudity or pornography</li>
                <li> Material that infringes intellectual property or other proprietary rights of any third party</li>
                <li>Material the posting of which is tortious or violates a contractual or fiduciary obligation you or we owe to another party</li>
                <li>Piracy, hacking, viruses, worms, etc.</li>
                <li>Violence promotion and violent content</li>
                <li>Illegal content, breaching any applicable Estonian or foreign law</li>
                <li>Any link to or any image from a site containing any material outlined in these restrictions</li>
                <li>Any material deemed offensive or inappropriate by Tendbe OÜ, in its sole discretion</li>
            </ul>
            In addition, you may not use the Service if your website contains any content outlined in this section.</p>
 {/* ############## */}
            <p>
            <h4>TERMINATION</h4>
          Tendbe reserves the right to terminate or take steps to terminate your polls,
          messages and/or access to the Service, in whole or in part, 
          without warning or previous notice, anytime and for any reason at its sole discretion, including without limitation any
          violation of a provision of these Terms and Conditions. Tendbe further reserves the right to remove any polls or accounts anytime
          and at its sole discretion without prior notice.
            </p>
{/* ############## */}
            <p>
           <h4>WARRANTIES</h4>
          The use of the Service and Tendbe is at your sole risk.
          Any warranty of Tendbe resulting from the use of the Service shall be expressly excluded, 
          as far as permissible by applicable law. No advice or information obtained by you from Tendbe and/or the Service shall create any kind of warranty.
            </p>
{/* ############## */}
            <p>
            <h4>LIABILITIES</h4>
          Notwithstanding anything else in these Terms and Conditions, 
          Tendbe OÜ shall not be liable for any losses or direct or indirect damages, 
          including without limitation damages for lost profits, data or other losses 
          arising out of or related to your or any third party’s use of the Service	
            </p>
{/* ############## */}
            <p>
            <h4>INDEMNIFICATION</h4>
            By using the Service you agree to indemnify and hold harmless Tendbe and its contractors, agents, employees, officers, directors, 
            shareholders and affiliates from any losses, damages or costs, including attorney’s fees,
             resulting from any claim or demand arising out of or related to your use of the Service,including without 
             limitation claims arising out of or related to your violation of these Terms and Conditions, or your violation of
              the rights of another person, or the content of any poll or message created through the Service.
              Should Tendbe be notified of a pending legal action, or receive notice of the filing of a legal action,
              it may seek written confirmation from you concerning your obligation to indemnify Tendbe and you agree to provide such confirmation promptly.
            </p>
{/* ############## */}
            <p><h4>DATA PROTECTION</h4>
            We put all necessary means to protect your data from all kinds of cyberattacks aiming at illegally collecting data from our database.
            We work with the highest quality service providers and all our data is stored in servers located in Germany.</p>
{/* ############## */}
            <p><h4>MODIFICATION</h4>
            Tendbe reserves the right to modify these Terms and Conditions at any time without prior notice.
             You agree to review these Terms and Conditions regularly. Failure to discontinue the use of our Service within fifteen (15) days of any such changes constitutes your acceptance of the new Terms and Conditions.</p>
{/* ############## */}
            <p><h4>GENERAL TERMS</h4>
            Information related to the viewership of your poll may be publicly disclosed on Tendbe or on other websites owned or operated by Tendbe, or any other websites.
            You agree that Tendbe may preserve your identity, any content and/or membership information and may use for any purpose and/or release any or all preserved identity, content or membership information to courts or other third parties if we believe, in our sole discretion, that you have or may have committed illegal activities, violated these Terms and Conditions, violated the rights of third parties, impaired in any respect the ability of Tendbe to operate the Service properly or endangered in any respect Tendbe OÜ or any third party.
            Tendbe OÜ does not claim ownership of the content you provide through the Service. However, by accepting these Terms and Conditions you grant Tendbe OÜ a world-wide, perpetual, royalty-free, and non-exclusive license to use, distribute, reproduce, modify, adapt, publicly perform, and publicly display such content for the purpose of providing and promoting the Service.
            Tendbe OÜ shall have the sole discretion as to what constitutes a violation of any of these Terms and Conditions. You may not assign or otherwise transfer this agreement and/or these Terms and Conditions to any third party.
           </p>
{/* ############## */}
            <p><h4>SEVERABILITY</h4>
            If any provision in these Terms and Conditions is found unenforceable, Tendbe OÜ reserves the right to modify the condition to comply with the applicable law. All remaining provisions shall remain valid and enforceable.
           </p>
{/* ############## */}
           <p><h4>ENTIRE AGREEMENT</h4>
           These Terms and Conditions contain the entire agreement of the parties. Any of your terms or the terms of any third party is expressly excluded. These Terms and Conditions supersede any prior written or oral agreements between the parties.
           </p>
{/* ############## */}
           <p><h4>CHOICE OF LAW AND JURISDICTION</h4>
           These Terms and Conditions and any agreement between Tendbe OÜ and you shall be exclusively governed by the laws of Estonia, excluding its conflict of law provisions and excluding the UN Convention on the international sale of goods (CISG).
          Exclusive jurisdiction for all disputes arising out of or in connection with these Terms and Conditions or the use of the Service shall have the courts of Estonia.
           </p>
          </TermsContent>
          <Illustrations />
        </TermsWrapper>
      </TermsSection>
    </Layout>
  );
};

export default Terms;
