import React from 'react';
import styled, {css, keyframes} from 'styled-components';

export interface CarouselItemInterface {
  index: number;
  activeIndex: number;
  item?: any;
  variant?: 'white' | 'default';
}

const fadeIn = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`;

const Item = styled.li<CarouselItemInterface>(
  ({index, activeIndex}) => css`
    display: ${index === activeIndex ? 'block' : 'none'};
    animation: ${fadeIn} 2s;
    position: relative;
  `,
);

const CarouselItem = (props: CarouselItemInterface) => {
  return (
    <Item {...props}>
      {props.item}
    </Item>
  );
}

export default CarouselItem;