export const pricing = (translator) => [
  {
    title: "free",
    list: [
      { text: "Unlimited poll creation", value: true },
      { text: "Unlimited poll & channel sharing", value: true },
      { text: "QR offline voting", value: true },
      { text: "Tendbe branded QR codes", value: true },
      { text: "50 members per channell", value: true },
      { text: "Maximum 3 channels", value: true },
    ],
  },
  {
    title: "premium",
    list: [
      { text: "Unlimited poll creation", value: true },
      { text: "Unlimited poll & channel sharing", value: true },
      { text: "Offline/online voting", value: true },
      { text: "Customizable QR codes", value: true },
      { text: "Unlimited members per channel", value: true },
      { text: "Unlimited channel creation", value: true },
      { text: "Data reports", value: true },
    ],
  },
];
