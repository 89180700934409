import {
  StyledForm,
  ButtonWrapper,
  SubmitButton,
  StyledImage,
} from "./ContactForm.styled";
import ShareIcon from "../../../static/share_icon.svg";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();

  const submitForm = (e: any) => {
    e.preventDefault();
  };

  const sendEmail = () => {
    window.location.href = 'mailto:business@tendbe.com';
  }

  return (
    <StyledForm onSubmit={submitForm}>
      <ButtonWrapper>
        <SubmitButton type="submit" onClick={sendEmail}>
          <StyledImage src={ShareIcon} alt="Send message" aria-hidden={true} />
          {t("contact.sendMsgBtn")}
        </SubmitButton>
      </ButtonWrapper>
    </StyledForm>
  );
};

export default ContactForm;