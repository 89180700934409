import styled, { css } from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { device } from "../../../utils/device";

export const StyledFooter = styled.footer(
  ({ theme }) => css`
    background: ${theme.colors.white};
  `
);

export const StyledFooterWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    box-sizing: border-box;
    @media ${device.laptop} {
      padding: 2rem 0;
    }
  `
);

export const StyledNav = styled.nav(({ theme }) => css``);

export const StyledList = styled.ul(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    justify-content: center;
  `
);

export const StyledListItem = styled.li(
  ({ theme }) => css`
    padding: 0;
    margin-bottom: 1rem;
  `
);

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    text-decoration: none;
    margin: 0 0.8rem;
    outline-color: ${theme.colors.primary[0]};
    &:hover {
      color: rgba(36, 74, 120, 0.6);
    }
  `
);

export const StyledCopyWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
    justify-content: center;
  `
);

export const StyledCopyText = styled.p(
  ({ theme }) => css`
    text-align: center;
  `
);
