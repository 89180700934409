import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/containers/Layout.component";
import {
  Illustrations,
  TermsContent,
  TermsSection,
  TermsWrapper,
} from "../styles/Terms.styled";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <Layout fixedContent>
      <TermsSection>
        <TermsWrapper>
          <TermsContent>
            <h1>{t("privacy.title")}</h1>
            <p>
            <h3>Updated December 15th 2022</h3>
Thank you for visiting www.tendbe.com, which is owned by <a href="https://www.linkedin.com/company/64863001/" >Tendbe OÜ</a>, and hosted in Germany. We strive to make our Privacy Policy easy to understand. While we encourage you to fully review our Privacy Policy, we provide a summary below that describes in general terms what we do and what we don’t do with your personal information. 
We also encourage you to review the Site Terms of Service, which is the agreement between you and Tendbe that governs your use of the Site, as well as provides the terms and conditions under which Tendbe provides, and you may use, the Tendbe Service.
In this Privacy Policy, we refer to our consumers as “Visitors” and “Users.” If you visit our Site and do not register, we refer to you as a “Visitor.” If you choose to register on our Site, we refer to you as a “User.” Both Users and Visitors can use our polling services.
            </p>
            <p>
            <h3>What types of personal data do we collect?</h3>
            We collect no personal data that could be traced back to you.
            For the sake of enabling voting we do store your: 
            <ul>
              <li>Device OS</li>
              <li>Date of your vote</li>
              <li>Device Model</li>
              <li>Browser Version</li>
            </ul>
We also ask for impersonal data such as: gender, age and profession, this is left to the freedom and consent of our users. This data cannot be traced to your personal identity whatsoever, we use it to enable our clients to learn more about their voters without jeopardizing your confidentiality.
        </p>
            <p>
            <h3>Cookies Usage</h3>
Tendbe.com uses cookies that are essential to browse the website and track visitors, their usage of the Tendbe OÜ website, and their website access preferences. A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns.Tendbe.com visitors can accept or decline the use of non-necessary cookies on a banner shown once on the website, with the drawback that certain features of Tendbe OÜ’s websites may not function properly without the aid of necessary cookies. The banner is always accessible in a small ribbon at the bottom of the screen.
            </p>           
            <p>
              <h3>What is the purpose and the legal grounds for processing your personal data?</h3>
              We always process your personal data for a specific purpose and only process the personal data relevant to achieve that purpose.
We process your personal data based on the following legal grounds for:
              <ul>
                <li>
              The performance of a contract to which you are a party or in order to take steps at your request prior to entering into a contract.</li>
                <li>Based on your consent, voluntarily provided by you (e.g. when you create an account).</li>
                <li>Compliance with a legal and regulatory obligations to which we are subject.</li>
                <li>The purposes of our legitimate interests.</li>
              </ul>
              We process your personal data for the following purposes:
              <ul>
                <li>Managing our relationship with you, including communicating with you in relation to our products and services (for example, when you register for an event).</li>
                <li>Providing and improving our products and services.</li>
                <li>Meeting our legal and regulatory obligations.</li>
              </ul>
            </p>
            <p>
            <h3>Do we share your personal data with our clients?</h3>
                Tendbe OÜ does not share email addresses or Usernames linked to the Tendbe accounts, both free or premium, to any third party.
                We do ask for impersonal data such as: Gender, age and profession in order to sharpen the data reports we provide to our clients. None of this information can be traced to your votes nor to your accounts or your votes as a guest.  
                We store and process your personal data on servers located in the Federal Republic of Germany. 
                If you would like to find out more about the appropriate safeguards that we have in place to govern the transfer of your personal data you can <a href="mailto=team@tendbe.com">contact us.</a>
            </p>
            <p>
            <h3>How long do we keep your personal data?</h3>
Your account email, password, usernames and all your activities inside the Tendbe application such as votes, comments and likes are stored in our servers as long as your account still exists, as soon as you delete your account, all these information are permanently deleted, only your comments will remain under a anonymous name called: Tendbe user.
Impersonal data collected from guest voters are untraceable to voters and therefore are not considered personal data. They will be used to build impersonal data reports for our clients to help them identify their voters' general demographics.  

            </p>
            <p>
            <h3>How do we keep your personal data secure?</h3>
We have in place appropriate technical and organizational measures to prevent unauthorized or unlawful access to the personal data you have provided to us. As complete data security cannot be guaranteed for communication via emails, instant messaging, and similar means of communication, we would recommend sending any particularly confidential information by an alternative secure means.

            </p>
            <p>
              <h3> What are your privacy rights and how can you exercise them?</h3>
                You have the right to:
              <ul>
                <li>Access your personal data and to be provided with certain information in relation to it, such as the purpose for which it is processed, the persons to whom it is disclosed and the period for which it will be stored.</li>
                <li>Require us to correct any inaccuracies in your personal data without undue delay;</li>
                <li>Require us to erase your personal data.</li>
                <li>Complete deletion of your data if you </li>
                <li>Receive the personal data which you have provided to us, in a machine readable format</li>
              </ul>
              Please note that these rights are not absolute and are subject to certain exemptions under applicable data protection law.
              As a startup, Tendbe OÜ puts privacy protection as one of its highest priorities, we are working towards being 100% GDPR compliant, and as we grow and the number of users increases, we will put more effort into achieving this goal. We have no intention nor is it in our business model to make money using any personal data from any of our users or clients. 
              If you have any questions or would like to exercise any of your rights please <a href="mailto@team@tendbe.com">contact us</a>
            </p>
          </TermsContent>
          <Illustrations />
        </TermsWrapper>
      </TermsSection>
    </Layout>
  );
};

export default Privacy;
