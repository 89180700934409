import styled, { css } from 'styled-components';
import { device } from '../../../utils/device';

export const TestimonialsSection = styled.section(
  ({ theme }) => css`
    background-color: white;
    background-repeat: no-repeat;
    padding: 10rem 0 0 0;
    z-index: -1;
   
    @media screen and ${device.laptop} {
      padding: 10rem 5rem 0 0;
    }
    @media screen and ${device.desktopL} {
      margin-top: 7vh;
    }
  `
);

export const TestimonialsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;

  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const TestimonialsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  padding: 50px 0 50px 0;
 
  @media ${device.laptop} {
    width: 50%;
  }
`;

export const TestimonialsLogoContent = styled.div`
  display: flex;
  border-top:2px dotted #4f5d6256;
  border-bottom:2px dotted #4f5d6256;
  padding: 15px 10px 10px 0;
  flex-direction: row;
  justify-content: space-between;
`;

export const TestimonialsTitle = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 30px;
    line-height: 1.714;
  `
);

export const TestimonialsLogo = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

export const TestimonialsDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary['dark-90']};
    margin: 0 0 30px;
    font-size: ${theme.typography.sizes.small};
    line-height: 1.114;
    text-align: justify;
    overflow: hidden;
    white-space: pre-line;
  `
);
