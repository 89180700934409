/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";

import Logo from "../../atoms/Logo/Logo.component";
import Navbar from "../../atoms/Navbar/Navbar.component";
import { HeaderContainer, HeaderWrapper } from "./Header.styled";

const Header = ({fixedContent}) => {
  const [isFixed, setFixed] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper fixedContent fixed={isFixed} ref={ref}>
      <HeaderContainer>
        <Logo />
        <Navbar />
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
