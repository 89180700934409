import React from "react";
import logo from "../../../static/tb_logo.png";
import { LogoWrapper, LogoImage } from "./Logo.styled";

const Logo = () => {
  const onScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <LogoWrapper>
      <LogoImage src={logo} alt="Tendbe Logo" onClick={onScrollToTop} />
    </LogoWrapper>
  );
};

export default Logo;
