import styled, { css } from "styled-components";
import { CarouselItemInterface } from "./CarouselItem.component";

export const CarouselComponent = styled.div`
  width: 100%;
  text-align: center;
`;

export const CarouselInner = styled.ul`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
`;

export const CarouselControls = styled.div`
  width: 100%;
  display: block;
  float: left;
  position: relative;
  margin-top: 10px;
`;

export const CarouselNav = styled.div<CarouselItemInterface>(
  ({ theme, index, activeIndex, variant = 'default' }) => {
    const variantStyles = {
      default: css`
        background-color: ${index === activeIndex
        ? "#5b5b5b"
        : "rgba(91, 91, 91, .5)"};
      `,
      white: css`
        background-color: ${index === activeIndex
        ? theme.colors.white
        : "rgba(255, 255, 255, .5)"};
      `,
    };
    return css`
      height: 15px;
      width: 15px;
      ${variantStyles[variant]};
      border-radius: 50%;
      display: inline-block;
      margin: 3px;
    `;
  }
);
