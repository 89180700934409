import { useTranslation } from "react-i18next";

import {
  HowItWorksContent,
  HowItWorksDescription,
  HowItWorksSection,
  HowItWorksTitle,
  HowItWorksWrapper,
} from "./HowItWorks.styled";

const HowItWorks = () => {
  const { t } = useTranslation(); 

  return (

      <HowItWorksSection id="how-it-works">
      <HowItWorksWrapper>
          <HowItWorksContent>
            <HowItWorksTitle>{t("howitworks_title")}</HowItWorksTitle>
            <HowItWorksDescription>
              {t("howitworks_description")}
            </HowItWorksDescription>
          </HowItWorksContent>
      </HowItWorksWrapper>
      </HowItWorksSection>
  );
};

export default HowItWorks;
