import styled from 'styled-components';

export const LogoWrapper = styled.figure`
  margin: 0;
  width: 170px;
`;

export const LogoImage = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;