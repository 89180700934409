import eduardo from "../static/team/tb-team-eduardo.jpg";
import michael from "../static/team/tb-team-michael.jpg";
import karim from "../static/team/tb-team-karim.gif";
import nikos from "../static/team/tb-team-nikos.gif";
import pishon from "../static/team/tb-team-pishon.gif";
import saydd from "../static/team/tb-team-saydd.png";
import user from "../static/tb-icon.png";
import victor from "../static/team/tb-team-victor.gif";

export const teamSection = {
  title: "Our Startup Adventure",
  description:
    "Starting in early 2019, Tendbe was built with passion by a team of entrepreneurs and digital nomads from around the world. We have made it our difficult mission to democratize access to data while protecting fundamental rights of confidentiality. We make it easier to vote, collect and analyze opinions for a more inclusive world with as many people as possible participating in the decision-making process.\nIf you are curious to know more about us, please feel free to contact us by filling our contact form.",
};

export const teamMembers = [
  {
    name: "Karim Elloumi",
    role: "Co-Founder & Business Developer",
    photo: karim,
    contact: "https://www.linkedin.com/in/karimelloumi",
  },
  {
    name: "Nikos Vassiliadis",
    role: "Devops Engineer",
    photo: nikos,
    contact: "https://www.linkedin.com/in/nvass",
  },
  {
    name: "Victor Moscosa",
    role: "Marketing & Design",
    photo: victor,
    contact: "https://www.linkedin.com/in/moscosa",
  },
  {
    name: "Eduardo Sánchez",
    role: "Fullstack Engineer",
    photo: eduardo,
    contact: "https://www.linkedin.com/in/jedsaro",
  },
  {
    name: "Manuel Sánchez",
    role: "Founder & Tech Lead",
    photo: pishon,
    contact: " https://www.linkedin.com/in/mansan",
  },
  {
    name: "Saydd Salas",
    role: "Fullstack Engineer",
    photo: saydd,
    contact: "https://www.linkedin.com/in/ssalasmx",
  },
  {
    name: "Michael Ling",
    role: "Data analyst",
    photo: michael,
    contact: "https://www.linkedin.com/in/michaellingrios",
  },
  {
    name: "This could be you!",
    role: "Join us!?",
    photo: user,
    contact: "https://www.linkedin.com/company/tendbe",
  }
];
