import React from 'react';
import { Preloader, PreloaderGif } from './LoadingScreen.styled';

const LoadingScreen = () => (
  <Preloader>
    <PreloaderGif aria-busy="true" aria-label="Loading, please wait." role="progressbar" />
  </Preloader>
);

export default LoadingScreen;
