import styled, { css } from "styled-components";
import { device, deviceMax } from "../../../utils/device";

export const PricingWrapper = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.white};
    min-height: 800px;
    @media ${deviceMax.laptopMax} {
      min-height: 1170px;
    }
  `
);

export const PricingContainer = styled.div(
  ({ theme }) => css`
    box-sizing: content-box;
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    padding: 2.5rem 1rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;

    @media ${device.laptop} {
      font-size: 16px;
    }
  `
);

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const CardBorder = styled.div(
  ({ theme }) => css`
    border: 0.1rem solid #dedada;
    border-radius: 3rem;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50rem;
    z-index: 1;
    div:nth-of-type(3) {
      background: ${theme.colors.tbBlue.base};
      color: #fff;
    }
    @media ${deviceMax.laptopMax} {
      width: 80%;
    }
    @media ${deviceMax.tabletMax} {
      width: 90%;
    }
    @media ${deviceMax.mobileLMax} {
      height: 60rem;
    }
    @media ${device.laptop} {
      flex-direction: row;
      height: 30rem;
    }
  `
);

export const CardElement = styled.div(
  ({ theme }) => css`
    position: relative;
    margin: 2rem;
    border-radius: 3rem;
    padding: 0 1rem;
    height: 350px;
    div:nth-of-type(3n) {
      background: ${theme.colors.tbBlue.base};
    }
    @media ${device.mobileL} and (${deviceMax.laptopMax}) {
      padding: 0 0.5rem;
    }
  `
);

export const CardTitle = styled.h4(
  ({ theme }) => css`
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: center;
    text-transform: uppercase;
  `
);

export const PricingGuy = styled.div`
  position: absolute;
  background: url("images/pricing_guy.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  z-index: 2;
  height: 15em;
  width: 10em;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -1rem;
  @media ${device.laptop} {
    bottom: -90px;
  }
  @media ${deviceMax.laptopMax} {
    bottom: -12rem;
  }
  
  @media ${deviceMax.mobileLMax} {
    display: none !important;
  }
`;

export const PricingBarGradient = styled.div`
  position: absolute;
  background: url("images/pricing_gradient_bar.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  z-index: 2;
  width: 12em;
  height: 5em;
  top: -2em;
  right: 0;
  left: 0;
  margin: auto;
`;

export const PricingTitle = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 30px;
  `
);

export const PricingSubTitle = styled.h4(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 30px;
  `
);

export const Separator = styled.div`
  border-top: 0.1rem solid #dedada;
  margin-left: 4rem;
  margin-right: 4rem;
  @media ${device.laptop} {
    border-left: 0.1rem solid #dedada;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
`;

export const DataContainer = styled.div`
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      .true {
        color: #34f034;
        font-size: 1rem;
      }
      .false {
        color: #f54343;
      }
      svg {
        margin-right: 0.5rem;
        font-size: 0.8rem;
      }
      margin-bottom: 1rem;
    }
  }
`;

export const AppLink = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.tbBlue.base};
    text-decoration: none;
    font-size: ${theme.typography.sizes.extraLarge};
    margin: 2rem 0 0;
    text-transform: none;
    @media ${device.laptop} {
      font-size: ${theme.typography.sizes.Large};
    }
  `
);

export const Button = styled.a(
  ({ theme }) => css`
    position: absolute;
    width: 15rem;
    border-radius: 2em;
    background-color: ${theme.colors.tbBlue.base};
    color: #fff;
    outline: none;
    border: none;
    padding: 12.5px 0;
    text-align: center;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    bottom: -60px;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
    transition: transform 0.8s,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
    @media ${device.laptop} {
    }
  `
);
