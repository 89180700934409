import icon1 from "../static/features/icon01.png";
import icon2 from "../static/features/icon02.png";
import icon3 from "../static/features/icon03.png";
import icon4 from "../static/features/icon04.png";
import icon6 from "../static/features/icon06.png";

export const featuresSection = {
  title: "Features",
};

export const getFeaturesTranslated = (t) => ([
  {
    id: 1,
    title: t("features_title_01"),
    description: t("features_description_01"),
    icon: icon1,
  },
  {
    id: 2,
    title: t("features_title_02"),
    description: t("features_description_02"),
    icon: icon2,
  },
  {
    id: 3,
    title: t("features_title_03"),
    description: t("features_description_03"),
    icon: icon3,
  },
  {
    id: 4,
    title: t("features_title_04"),
    description: t("features_description_04"),
    icon: icon4,
  },
  {
    id: 6,
    title: t("features_title_06"),
    description: t("features_description_06"),
    icon: icon6,
    link: "https://gdpr.eu/right-to-be-forgotten/"
  },
]);
