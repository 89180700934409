import React from 'react';
import Header from '../molecules/Header/Header.component';
import Footer from '../molecules/Footer/Footer.component';

interface IProps {
  fixedContent?: boolean;
  children: React.ReactNode;  
}

const Layout = ({ fixedContent, children }: IProps) => (
  <div>
    <Header fixedContent/>
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
