import { createGlobalStyle } from "styled-components";
import baseTheme from "../themes/baseTheme";

export default createGlobalStyle`
  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  
  body {
    color: ${baseTheme.colors.primary.base};
    font-family: ${baseTheme.typography.fonts.primary};
    margin: 0;
    overflow-x: hidden;
  }
`;
