import React from "react";
import {
  FaqSection,
  FaqSectionWrapper,
  FaqSectionTitle,
  FaqQuestionSection,
} from "./Faqs.styled";
import Questions from "../../atoms/Questions/Questions.component";
import { getTranslatedQuestions } from "../../../constants/faqs";
import { chunk } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const { t } = useTranslation();
  let trans =  getTranslatedQuestions(t);  
  const chunks = chunk(trans, trans.length / 2);
  
  return (
    <FaqSection id="faqs">
      <FaqSectionWrapper>
        <FaqSectionTitle>{t("faqs.title")}</FaqSectionTitle>
        <FaqQuestionSection>
          <Questions id="Question" questions={chunks[0]} />
          <Questions id="Question2" questions={chunks[1]} />
        </FaqQuestionSection>
      </FaqSectionWrapper>
    </FaqSection>
  );
};

export default Faqs;
