import React from "react";

import {
  ContactSection,
  ContactWrapper,
  ContactSectionTitle,
  ContactDescription,
} from "./Contact.styled";
import ContactForm from "../../molecules/ContactForm/ContactForm.component";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContactSection id="contact">
      <ContactWrapper>
        <ContactSectionTitle>{t("contact.title")}</ContactSectionTitle>
        <ContactDescription>{t("contact.description")}</ContactDescription>
        <ContactForm />
      </ContactWrapper>
    </ContactSection>
  );
};

export default Contact;
